import './TextField.scss';
import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { TextField as MuiTextField, Tooltip } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import ToolTip from '../../ToolTip';

export default function TextField (props) {
  const {
    field,
    value,
    error,
    handleChange,
    disabled,
    designedValue,
    showLabel,
    showHelperText,
    readonly
  } = props;
  const fieldId = field.get('id');
  const type = field.get('type') === 'number' ? 'text' : field.get('type');

  const unit = field.get('unit', null);
  const label = (<>
    {field.get('label') ?? null}
    {unit ? (<>&nbsp;<span className='unit'>({unit})</span></>) : null}
  </>);

  return <div>
    {
      showLabel ?
        <div className='textfield-label-wrap'>
          <label className='textfield-label' key={fieldId}>
            {label}
          </label>
          {field.get('toolTip', null) ? (<div style={{ marginTop: '-10px' }}><ToolTip helpText={field.get('toolTip', null)} ></ToolTip></div>) : null}
        </div>
        : null
    }
    {!readonly ? <MuiTextField
      data-testid={`text-field-${fieldId}`}
      fullWidth
      key={`text-field-${fieldId}`}
      id={`text-field-${fieldId}`}
      name={`text-field-${fieldId}`}
      type={type}
      value={value !== null ? value : ''}
      onChange={(e) => handleChange(fieldId, e.target.value)}
      error={Boolean(error)}
      disabled={disabled}
      helperText={
        showHelperText ?
          <span data-testid={`text-field-helper-text-${fieldId}`}>
            {(error) ?
              <>
                {error}<br />
              </> : null}
            {field.get('helperText')}
          </span> : null
      }
      variant='outlined'
      InputLabelProps={{ shrink: false }}
      InputProps={{
        endAdornment: (
          <>
            {designedValue ?
              <Tooltip
                title={`Designed value is ${designedValue}`}
                enterTouchDelay={0}>
                <ErrorIcon style={{ fontSize: 20, cursor: 'pointer', color: '#fcd54c' }} />
              </Tooltip>
              : null}
          </>
        ),
      }}
      inputProps={{ 'aria-label': field.get('label') }}
    /> : <p className="textfield-value">{value}</p>}

  </div>;


}

TextField.propTypes = {
  field: ImmutablePropTypes.map,
  error: PropTypes.string,
  value: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  readonly: PropTypes.bool,
  disabled: PropTypes.bool,
  designedValue: PropTypes.number,
  showLabel: PropTypes.bool,
  showHelperText: PropTypes.bool,
};

TextField.defaultProps = {
  readonly: false,
  disabled: false,
  designedValue: null,
  showLabel: true,
  showHelperText: true,
};

TextField.whyDidYouRender = true;
